import Link from 'next/link'
import Image from 'next/image'
import { useAuth } from '@clerk/nextjs'

// Complex
export const ButtonAuth: React.FC = () => {
  const { isSignedIn } = useAuth()

  return (
    <div className="flex items-center">
      {isSignedIn ? (
        <Link href="/app/profile">
          <button className="btn-primary">
            Access Dashboard
            <Image
              src="/icons/arrowR.svg"
              alt="Arrow Icon"
              width={20}
              height={20}
              className="ml-2"
            />
          </button>
        </Link>
      ) : (
        <div className="flex space-x-5">
          <Link href="/app/sign-in">
            <button className="btn-primary">
              Continue
              <Image
                src="/icons/arrowR.svg"
                alt="Arrow Icon"
                width={20}
                height={20}
                className="ml-2"
              />
            </button>
          </Link>
        </div>
      )}
    </div>
  )
}
