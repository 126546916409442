'use client'

import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'
import { Dialog, DialogPanel, PopoverGroup } from '@headlessui/react'
import { Logo } from './logo'
import { ButtonAuth } from './buttonAuth'

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const closeMobileMenu = () => {
    setMobileMenuOpen(false)
  }

  return (
    <header className="bg-transparent">
      <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 px-4">
        <div className="flex lg:flex-1">
          <Logo />
          <PopoverGroup className="hidden ml-6 lg:flex lg:gap-x-2 items-center z-40">
            <Link
              href="/pricing"
              className="text-sm leading-6 text-aredegraytext hover:underline rounded-3xl p-2 transition duration-200">
              Pricing
            </Link>
            <Link
              href="/updates"
              className="text-sm leading-6 text-aredegraytext hover:underline rounded-3xl p-2 transition duration-200">
              Updates
            </Link>
            <Link
              href="/blog"
              className="text-sm leading-6 text-aredegraytext hover:underline rounded-3xl p-2 transition duration-200">
              Blog
            </Link>
            <Link
              href="/support"
              className="text-sm leading-6 text-aredegraytext hover:underline rounded-3xl p-2 transition duration-200">
              Support
            </Link>
          </PopoverGroup>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 rounded-xl p-2.5 border border-aredegray"
            onClick={() => setMobileMenuOpen(true)}>
            <span className="sr-only">Open menu</span>
            <Image src="/icons/burger.svg" alt="Menu" width={24} height={24} />
          </button>
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <ButtonAuth />
        </div>
      </nav>

      {/* Mobile Menu */}
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-aredewhite px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-aredegray animate-slide-in-left">
          <div className="flex items-center justify-between">
            <div className="-ml-2">
              <Logo />
            </div>
            <button
              type="button"
              className="-m-2.5 rounded-xl p-2.5 border border-aredegray"
              onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <Image
                src="/icons/burger.svg"
                alt="Close"
                width={24}
                height={24}
              />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-aredegray">
              <div className="space-y-2 py-4">
                <Link
                  href="/pricing"
                  onClick={closeMobileMenu}
                  className="-mx-3 block rounded-3xl px-3 py-3 leading-7 text-aredegraytext hover:bg-aredegray transition duration-200">
                  Pricing
                </Link>
                <Link
                  href="/updates"
                  onClick={closeMobileMenu}
                  className="-mx-3 block rounded-3xl px-3 py-3 leading-7 text-aredegraytext hover:bg-aredegray transition duration-200">
                  Updates
                </Link>
              </div>
              <div className="py-6" onClick={closeMobileMenu}>
                <ButtonAuth />
              </div>
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>
  )
}
