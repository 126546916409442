import React from 'react'
import Link from 'next/link'
import Image from 'next/image'

// Logo
export const Logo = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Link
      href="/"
      className="flex items-center p-2 rounded-xl hover:bg-aredegray">
      <div>
        <Image
          src="/logos/publicgains.png"
          alt="PublicGains Logo"
          className="h-8 w-8 md:h-10 md:w-10"
          width={128}
          height={128}
        />
      </div>
      {children}
    </Link>
  )
}

// Logo Signed In
export const LogoSignedIn = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Link
      href="/app/profile"
      className="flex items-center p-2 rounded-xl hover:bg-aredegray">
      <div>
        <Image
          src="/logos/publicgains.png"
          alt="PublicGains Logo"
          className="h-8 w-8"
          width={128}
          height={128}
        />
      </div>
      {children}
    </Link>
  )
}
